/*
Shared
*/

.bi-home-container {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
}


/*
Header
*/

.bi-home-header {
    width: 100%;
}

.bi-home-header-hero-image {
    position: relative;
    background: linear-gradient(0deg, rgba(109, 8, 18, 0.80), rgba(109, 8, 18, 0.80)), url("../../../assets//images/home_hero_image.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 80vh;
}

.bi-home-header-hero-text {
    margin-top: 12.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 3px;
    color: #fff;
}

.bi-home-header-hero-text p {
    font-size: 32px;
    text-align: center;
    width: 500px;
    margin: 0;
    padding: 0;
}

.bi-home-header-welcome {
    position: absolute;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
    width: 700px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(41, 41, 41, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.bi-home-header-welcome-logo {
    display: block;
    margin: auto;
    height: 250px;
    width: 250px;
}

.bi-home-header-fix {
    height: 150px;
}


/*
Body
*/

.bi-home-body-appointment-section {
    margin-top: 24px;
    width: 100%;
    height: 125px;
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bi-home-body-location-section {
    margin-top: 24px;
    width: 100%;
    height: 125px;
    background-color: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bi-home-body-appointment-section-button {
    padding: 12px 20px 12px 20px;
    font-size: 24px;
    font-family: "DINOT-Bold", sans-serif;
    margin: 3px;
    background-color: Transparent;
    border: 2px solid rgb(0, 0, 0);
    cursor: pointer;
    color: rgb(0, 0, 0);
    border-radius: 25px;
    letter-spacing: 2px;
}

.bi-home-body-appointment-section-button:hover {
    background-color: #383838;
    color: #fff;
}

.bi-home-body-dr-julie-nos-wrapper {
    min-width: 100%;
}

.bi-home-body-dr-julie-nos-image-wrapper {
    margin-top: 15px;
    float: right;
    padding: 30px;
}

.bi-home-body-dr-julie-nos-welcome-video {
    width: 100%;
    max-height: 100%;
}

.bi-home-body-dr-julie-nos-image {
    height: 300px;
    width: auto;
}

.bi-home-body-services-section-cards {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.bi-home-body-services-section-more-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bi-home-body-make-appointment-section {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #F6F6F6;
}

.bi-home-body-make-location-section {


    width: 100%;
    background-color: #fff;
    margin-bottom: 35px;
}

.bi-home-body-make-appointment-section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.bi-home-body-contact-section {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.bi-home-body-contact-section td {
    text-align: left;
    padding: 5px;
    font-size: 16px;
    font-family: "DINOT-Bold", sans-serif;
}

.bi-home-body-contact-section-icon {
    font-size: 30px;
    margin-left: 50px;
}

.bi-home-body-contact-section a:hover {
    color: #6D0812;
}


/*
Mobile Overides
*/

@media only screen and (max-width: 720px) {
    .bi-home-container {
        width: 90%
    }
    .bi-home-header-hero-image {
        min-height: 600px;
    }
    .bi-home-header-welcome {
        bottom: -300px;
        width: 80%;
    }
    .bi-home-header-fix {
        height: 300px;
    }
    .bi-home-header-hero-text {
        margin-top: 0px;
    }
    .bi-home-header-hero-text p {
        font-size: 28px;
        max-width: 325px;
    }
    .bi-home-body-dr-julie-nos-image-wrapper {
        float: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bi-home-body-services-section-cards {
        width: 100%;
    }
    .bi-home-body-contact-section td {
        font-size: 12px;
        font-family: "DINOT-Regular", sans-serif;
    }
    .bi-home-body-contact-section-icon {
        font-size: 16px;
        margin-left: 5px;
    }
}

.bi-empty-space {
    color: #F6F6F6;
}