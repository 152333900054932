.bi-button {
    padding: 8px 14px 8px 14px;
    font-size: 0.8rem;
    font-family: "DINOT-Bold", sans-serif;
    margin: 3px;
    background-color: Transparent;
    border: 2px solid #fff;
    cursor: pointer;
    color: #fff;
    border-radius: 25px;
    letter-spacing: 2px;
}

.bi-button:hover {
    background-color: rgba(255, 255, 255, 0.308);
}

.bi-button text {
    text-align: center;
    vertical-align: middle;
}