/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  font-size: 16px;
  font-family: "DINOT-Regular", sans-serif;
  border-bottom: 1px solid #6D0812;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: rgb(109, 8, 18, 0.1);
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}
