.service-card {
    margin-top: 24px;
    width: 100%;
    background-color: #F6F6F6;
}

.service-card-image {
    height: 200px;
    width: 60%;
    position: relative;
}

.service-card-info {
    height: 200px;
    width: 40%;
    padding: 0px 5px 5px 10px;
    box-sizing: border-box;
    background-color: #F6F6F6;
}

.service-card-image-pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(61, 11, 13, 0.70), rgba(61, 11, 13, 0.70))
}

.service-card-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 20%;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    -moz-opacity: 0.2;
    /* Firefox and Mozilla browsers */
    -webkit-opacity: 0.2;
    /* WebKit browser e.g. Safari */
    filter: alpha(opacity=20);
    /* For IE8 and earlier */
    background-color: #6D0812;
}

@media only screen and (min-width: 720px) {
    .service-card {
        display: flex;
        align-items: stretch;
    }
}

@media only screen and (max-width: 720px) {
    .service-card {
        margin-top: 24px;
    }
    .service-card-image {
        width: 100%;
    }
    .service-card-info {
        width: 100%;
        height: 125px;
    }
}