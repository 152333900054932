body {
    margin: 0;
    font-family: "DINOT-Regular", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #383838;
    letter-spacing: .3px;
}

h1 {
    font-size: 32px;
    font-family: "DINOT-Bold", sans-serif;
    color: #6D0812;
}

h2 {
    font-size: 24px;
    font-family: "DINOT-Bold", sans-serif;
    color: #6D0812;
}

h3 {
    font-size: 18.72px;
}

h4 {
    font-size: 16px;
}

p {
    font-size: 20px;
}

a {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
}

@font-face {
    font-family: DINOT-Regular;
    font-weight: normal;
    src: url("./assets/fonts/DINOT-Regular.otf") format("opentype");
}

@font-face {
    font-family: DINOT-Bold;
    font-weight: bold;
    src: url("./assets/fonts/DINOT-Bold.otf") format("opentype");
}