/*
Desktop
*/

.bi-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bi-navbar-right {
    position: absolute;
    right: 0;
    padding: 24px;
}

.bi-navbar-item {
    color: #fff;
    display: inline;
    font-family: "DINOT-Regular", sans-serif;
    text-align: center;
    letter-spacing: 2px;
}

.bi-navbar-item a {
    text-align: center;
    display: inline-block;
    padding: 24px;
}

.bi-navbar-item a:hover {
    font-family: "DINOT-Bold", sans-serif;
    text-decoration: underline;
}


/*
Mobile
*/

.bi-navbar-mobile {
    padding: 24px;
    overflow: hidden;
}

.bi-navbar-right-mobile {
    float: right;
}

.bi-navbar-item-mobile {
    padding: 24px;
}

.bi-navbar-item-mobile:hover {
    background-color: #fff;
    color: #6D0812;
    font-family: "DINOT-Bold", sans-serif;
}

.bi-navbar-container-mobile {
    background-color: #6D0812;
    color: #fff;
    position: absolute;
    z-index: 99 !important;
    right: 0;
    width: 100%;
    box-shadow: 2px 5px 4px #5858585d;
}

.bi-bars-icon {
    font-size: 24px;
    cursor: pointer;
    color: #fff;
}

.bi-navbar-mobile-show {
    display: block;
}

.bi-navbar-mobile-hidden {
    display: none;
}


/*
Logic
*/

@media only screen and (max-width: 871px) {
    .bi-navbar {
        display: none;
    }
}

@media only screen and (min-width: 871px) {
    .bi-navbar-mobile {
        display: none;
    }
}